import React from "react";
import styled from "styled-components";
import Bezpieczny1Img from "../assets/bezpieczny-1.png";
import Bezpieczny2Img from "../assets/bezpieczny-2.png";
import Bezpieczny3Img from "../assets/bezpieczny-3.png";
import Bezpieczny4Img from "../assets/bezpieczny-4.png";
import Bezpieczny5Img from "../assets/bezpieczny-5.png";
import Bezpieczny6Img from "../assets/bezpieczny-6.png";

const Section1Container = styled.div`
  background: #f2f2f2;
  padding: 40px 20px 0;

  @media screen and (min-width: 384px) {
    padding: 40px 20px 0;
  }

  @media screen and (min-width: 768px) {
    padding: 40px 40px 0;
  }

  @media screen and (min-width: 1024px) {
    padding: 40px 100px 0;
  }
`;

const SectionBottom = styled.div`
  background: #B71375;
  padding: 130px 20px 80px;
  margin-top: -70px;
  text-align: center;

  @media screen and (min-width: 384px) {
    padding: 130px 20px 80px;
  }

  @media screen and (min-width: 768px) {
    padding: 130px 40px 80px;
  }

  @media screen and (min-width: 1024px) {
    padding: 130px 100px 80px;
  }
`;

const StyledH1 = styled.h1`
  font-size: 32px;
  text-align: center;
  font-weight: bold;
  color: #000;
  text-align: center;
  margin: 0 auto;
`;

const GridContainer = styled.div`
  margin-top: 30px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 20px;

  @media screen and (min-width: 384px) {
    grid-template-columns: repeat(1, 1fr);
  }

  @media screen and (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (min-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const GridItem = styled.div`
  background: #fff;
  border-radius: 16px;
  -webkit-box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);

  font-weight: bold;
  line-height: 28px;
  text-align: center;
  color: #000;
  padding: 30px;

  h4 {
    color: #890E57;
    padding-bottom: 15px;
    font-weight: bold;
    font-size: 21px;
  }

  p {
    font-size: 16px;
    line-height: 24px;
    font-weight: normal;
  }

  img {
    display: block;
    margin: 0 auto 15px;
  }
`;

const ReadMore = styled.a`
background-color: #fff;
color: #B71375;
padding: 10px 40px;
border: none;
cursor: pointer;
border-radius: 8px;
font-size: 14px;
font-weight: 700;
line-height: 40px;
letter-spacing: 0em;
text-align: center;
font-family: "Source Sans 3", sans-serif;

@media screen and (min-width: 768px) {
  padding: 12px 10px;
  font-size: 18px;
  line-height: 28px;
}

@media screen and (min-width: 1024px) {
  padding: 12px 15px;
  font-size: 20px;
  line-height: 38px;
}

@media screen and (min-width: 1440px) {
  padding: 12px 40px;
  font-size: 22px;
  line-height: 48px;
}
`;

const Section1: React.FC = () => {
  return (
    <>
    <Section1Container>
      <StyledH1 style={{ marginTop: '20px', marginBottom: '60px' }}>Bezpieczny Salon Beauty to:</StyledH1>
      <GridContainer>
        <GridItem>
          <img src={Bezpieczny1Img} alt="" />
          <h4>Używanie środków ochrony</h4>
          <p>
            W zależności od rodzaju usługi, pracownicy salonu używają
            jednorazowych rękawiczek, ochrony twarzy oraz fartucha.
          </p>
        </GridItem>
        <GridItem>
          <img src={Bezpieczny2Img} alt="" />
          <h4>Sprzątanie i dezynfekcja</h4>
          <p>
            To główny i zarazem najczęściej pomijany obowiązek pracowników
            salonów. Salon musi być regularnie sprzątany, a wspólne powierzchnie
            i narzędzia dezynfekowane.
          </p>
        </GridItem>
        <GridItem>
          <img src={Bezpieczny3Img} alt="" />
          <h4>Procedury higieniczne</h4>
          <p>
            Każdy salon powinien posiadać jasne i przestrzegane przez
            pracowników czytelne procedury dotyczące utrzymywania higieny.
          </p>
        </GridItem>
        <GridItem>
          <img src={Bezpieczny4Img} alt="" />
          <h4>Używanie jednorazówek</h4>
          <p>
            Dla zachowania bezpieczeństwa w salonach powinno się używać narzędzi
            i tekstyliów jednorazowych. Ze względu na ich charakter i budowę nie
            nadają się do sterylizacji - należy je wyrzucić po każdym kliencie.
          </p>
        </GridItem>
        <GridItem>
          <img src={Bezpieczny5Img} alt="" />
          <h4>Mycie i dezynfekcja rąk</h4>
          <p>
            Ręce należy myć i dezynfekować na oczach klienta przed rozpoczęciem
            i po zakończeniu każdego zabiegu. W bezpiecznym salonie należy
            wymagać dezynfekcji rąk także od klientów.
          </p>
        </GridItem>
        <GridItem>
          <img src={Bezpieczny6Img} alt="" />
          <h4>Dezynfekcja stanowiska pracy</h4>
          <p>
            Przed każdym klientem stanowisko pracy musi być czyste i sterylne. W
            zależności od rodzaju wykonywanych usług pracownicy stosują
            jednorazowe materiały.
          </p>
        </GridItem>
      </GridContainer>
    </Section1Container>
    <SectionBottom>
      <StyledH1 style={{ color: '#fff', marginBottom: '30px' }}>
        Jak stworzyć Bezpieczny Salon
      </StyledH1>
      <ReadMore href="https://medisept.pl/maski/beauty/" target="_blank">
        Pobierz materiały
      </ReadMore>
    </SectionBottom>
    </>
  );
};

export default Section1;
