import React from "react";
import ProductCard from "./ProductCard";
import "./Products.css";

import Product1Img from "../../assets/products/1L_velox_spray_teatonic_PL.png";
import Product2Img from "../../assets/products/alodes_n_1L.png";
import Product3Img from "../../assets/products/velodes_skin_500ml_PL.png";
import Product4Img from "../../assets/products/velodes_cream_100ml_v2mat.png";
import Product5Img from "../../assets/products/velodes_soap_500ml.png";
import Product6Img from "../../assets/products/velox_wipes_na_puszka.png";
import Product7Img from "../../assets/products/velox_foam_extra_1L.png";
import Product8Img from "../../assets/products/viruton_forte_1L.png";
import Product9Img from "../../assets/products/quatrodes_extra_1L.png";
import Product10Img from "../../assets/products/velox_duo_wipes_puszka.png";
import Product11Img from "../../assets/products/MC_110_BK_1L.png";
import Product12Img from "../../assets/products/MC_211_500ml.png";
import Product13Img from "../../assets/products/MC311_500ml_PL.png";
import Product14Img from "../../assets/products/MC320_750ml.png";
import Product15Img from "../../assets/products/MC_220_500ml 1.png";
import styled from "styled-components";

const ProductsContainer = styled.div`
  padding: 40px 20px;

  @media screen and (min-width: 384px) {
    padding: 40px 20px;
  }

  @media screen and (min-width: 768px) {
    padding: 40px 40px;
  }

  @media screen and (min-width: 1024px) {
    padding: 40px 100px;
  }
`;

const Headline = styled.h1`
  font-size: 32px;
  text-align: center;
  font-weight: bold;
  color: #000;
  text-align: center;
  margin: 40px auto 10px;
`;

const Paragraph = styled.p`
  font-size: 16px;
  text-align: center;
  margin: 0 auto 40px;
  max-width: 100%;

  @media screen and (min-width: 768px) {
    max-width: 60%;
  }
`;

const MoreButton = styled.a`
  padding: 5px 24px;
  cursor: pointer;
  border-radius: 8px;
  border: 1px solid #fff;
  font-family: "Source Sans 3";
  font-size: 20px;
  font-weight: 400;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: center;
  color: #fff;
  background-color: #b71375;
  margin: 0 auto;
  font-weight: bold;
  display: inline-block;
`;

const Products = () => {
  const products = [
    {
      id: 1,
      img: Product1Img,
      title: "Velox Spray",
      description: "Mycie i dezynfekcja małych powierzchni",
      url: "https://bit.ly/3SsaRET",
    },
    {
      id: 2,
      img: Product2Img,
      title: "Alodes N",
      description: "Mycie i dezynfekcja narzędzi",
      url: "https://bit.ly/3SOA38T",
    },
    {
      id: 3,
      img: Product3Img,
      title: "Velodes Skin",
      description: "Dezynfekcja rąk",
      url: "https://bit.ly/4781qij",
    },
    {
      id: 4,
      img: Product4Img,
      title: "Velodes Cream",
      description: "Pielęgnacja dłoni",
      url: "https://bit.ly/49sfapF",
    },
    {
      id: 5,
      img: Product5Img,
      title: "Velodes Soap",
      description: "Mycie rąk",
      url: "https://bit.ly/466D33l",
    },
    {
      id: 6,
      img: Product6Img,
      title: "Velox Wipes NA",
      description: "Dezynfekcja powierzchni",
      url: "https://bit.ly/3udaiV9",
    },
    {
      id: 7,
      img: Product7Img,
      title: "Velox Foam Extra",
      description: "Dezynfekcja powierzchni wrażliwych na alkohol",
      url: "https://bit.ly/47o908d",
    },
    {
      id: 8,
      img: Product8Img,
      title: "Viruton Forte",
      description: "Dezynfekcja akcesoriów fryzjerskich",
      url: "https://bit.ly/47i5FYV",
    },
    {
      id: 9,
      img: Product9Img,
      title: "Quatrodes Extra",
      description: "Mycie i dezynfekcja dużych powierzchni",
      url: "https://bit.ly/3FVVAo5",
    },
    {
      id: 10,
      img: Product10Img,
      title: "Velox Duo Wipes ",
      description: "Dezynfekcja powierzchni",
      url: "https://bit.ly/3QvHheP",
    },
    {
      id: 11,
      img: Product11Img,
      title: "Mediclean 110 Floor",
      description: "Codzienne mycie podłóg",
      url: "https://bit.ly/3MyGyZw",
    },
    {
      id: 12,
      img: Product12Img,
      title: "Mediclean 211 Office",
      description: "Meble i sprzęt biurowy",
      url: "https://bit.ly/4782nqT",
    },
    {
      id: 13,
      img: Product13Img,
      title: "Mediclean 311 Foam",
      description: "Usuwanie kamienia i czyszczenie armatury łazienkowej",
      url: "https://bit.ly/466DrPl",
    },
    {
      id: 14,
      img: Product14Img,
      title: "Mediclean 320 WC",
      description: "Mycie sanitariów",
      url: "https://bit.ly/45Z2XG8",
    },
    {
      id: 15,
      img: Product15Img,
      title: "Mediclean 220 Glass",
      description: "Szyby i lustra",
      url: "https://bit.ly/3Qxf4EH",
    },
  ];

  return (
    <ProductsContainer id="products">
      <Headline>Produkty polecane do salonów beauty</Headline>
      <Paragraph>
        Środki do dezynfekcji pozwalają na usunięcie szkodliwych drobnoustrojów
        – wirusów bakterii, czy też prątków gruźlicy. Ich stosowanie jest
        niezbędne w każdym miejscu, gdzie zagrożenie zakażeniem jest wysokie:
        przede wszystkim w placówkach medycznych, ale także salonach urody czy
        tatuażu.
      </Paragraph>
      <div className="products-container">
        {products.map((product, index) => (
          <ProductCard key={index} product={product} />
        ))}
      </div>
      <div style={{ textAlign: "center", width: "100%", marginTop: "30px" }}>
        <MoreButton
          href="https://medisept.pl/produkty/?productscategory=67&productscategory2=all"
          target="_blank"
        >
          Zobacz wszystkie produkty
        </MoreButton>
      </div>
    </ProductsContainer>
  );
};

export default Products;
