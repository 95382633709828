import React from "react";
import styled from "styled-components";
import logo1x from "../assets/logo.png";
import logo2x from "../assets/logo@2x.png";
import HeaderImg from "../assets/header.jpg";
import Salon1Img from "../assets/salony/salon-1.png";
import Salon2Img from "../assets/salony/salon-2.png";
import Salon3Img from "../assets/salony/salon-3.png";
import Salon4Img from "../assets/salony/salon-4.png";
import Salon5Img from "../assets/salony/salon-5.png";
import Salon6Img from "../assets/salony/salon-6.png";
import Salon7Img from "../assets/salony/salon-7.png";

const HeaderContainer = styled.div`
  border-top: 20px solid #890e57;
  padding: 0;
  position: relative;
  background: #fff url(${HeaderImg}) no-repeat top center;
  background-size: auto;
  min-height: 850px;

  @media (min-width: 768px) {
    background-size: cover;
  }
`;

const LeftColumn = styled.div`
  align-items: center;
  padding: 0 20px;
  margin-bottom: 20px;
  margin-top: 40px;

  @media screen and (min-width: 768px) {
    padding-left: 50px;
    margin-bottom: 0px;
  }

  @media screen and (min-width: 1024px) {
    padding-left: 100px;
    margin-bottom: 0px;
  }
`;

const Logo = styled.img`
  width: 150px;
  height: auto;
  margin-right: 20px;
`;

const RegisterButton = styled.button`
  background-color: #b71375;
  color: white;
  padding: 6px 14px;
  border: none;
  cursor: pointer;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: center;
  font-family: "Source Sans 3", sans-serif;

  @media screen and (min-width: 768px) {
    padding: 6px 14px;
    font-size: 14px;
    line-height: 28px;
  }

  @media screen and (min-width: 1024px) {
    padding: 6px 24px;
    font-size: 16px;
    line-height: 38px;
  }

  @media screen and (min-width: 1440px) {
    padding: 6px 36px;
    font-size: 20px;
    line-height: 48px;
  }
`;

const ProductsButton = styled.button`
  background-color: transparent;
  color: #b71375;
  padding: 6px 14px;
  border: 1px solid #b71375;
  cursor: pointer;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: center;
  font-family: "Source Sans 3", sans-serif;
  margin-left: 0px;
  margin-top: 10px;
  display: block;

  @media screen and (min-width: 384px) {
    margin-left: 20px;
    margin-top: 0px;
    display: inline-block;
  }

  @media screen and (min-width: 768px) {
    padding: 6px 14px;
    font-size: 14px;
    line-height: 28px;
  }

  @media screen and (min-width: 1024px) {
    padding: 6px 24px;
    font-size: 16px;
    line-height: 38px;
  }

  @media screen and (min-width: 1440px) {
    padding: 6px 36px;
    font-size: 20px;
    line-height: 48px;
  }
`;

const StyledH1 = styled.h1`
  font-family: "Source Sans 3";
  font-size: 38px;
  font-weight: 700;
  line-height: 38px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 20px;
  margin-bottom: 10px;
  color: #890e57;

  @media screen and (min-width: 768px) {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 38px;
    line-height: 38px;
  }

  @media screen and (min-width: 1024px) {
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 48px;
    line-height: 48px;
  }

  @media screen and (min-width: 1440px) {
    margin-top: 40px;
    margin-bottom: 30px;
    font-size: 58px;
    line-height: 58px;
  }
`;

const StyledH3 = styled.h3`
  font-family: "Source Sans 3";
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 30px;

  @media screen and (min-width: 768px) {
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 20px;
  }

  @media screen and (min-width: 1024px) {
    margin-bottom: 30px;
    font-size: 18px;
    line-height: 24px;
  }

  @media screen and (min-width: 1440px) {
    margin-bottom: 40px;
    font-size: 20px;
    line-height: 28px;
  }
`;

const Question = styled.div`
  font-size: 18px;
  text-align: center;
  font-weight: bold;
  color: #000;
  margin-top: 60px;

  @media screen and (min-width: 768px) {
    font-size: 18px;
  }

  @media screen and (min-width: 1024px) {
    font-size: 28px;
  }

  @media screen and (min-width: 1440px) {
    font-size: 32px;
  }
`;

const SalonsContainer = styled.div`
  margin-top: 30px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 20px;
  padding: 0 20px;

  @media screen and (min-width: 384px) {
    grid-template-columns: repeat(2, 1fr);
    padding: 0 20px;
  }

  @media screen and (min-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
    padding: 0 40px;
  }

  @media screen and (min-width: 1024px) {
    grid-template-columns: repeat(4, 1fr);
    padding: 0 100px;
  }

  @media screen and (min-width: 1440px) {
    grid-template-columns: repeat(7, 1fr);
  }
`;

const SalonItem = styled.div`
  background: #fff;
  border-radius: 16px;
  -webkit-box-shadow: 0px -6px 12px -8px rgba(0, 0, 0, 0.39);
  -moz-box-shadow: 0px -6px 12px -8px rgba(0, 0, 0, 0.39);
  box-shadow: 0px -6px 12px -8px rgba(0, 0, 0, 0.39);
  font-size: 16px;
  font-weight: bold;
  line-height: 28px;
  text-align: center;
  color: #000;
  padding: 20px;

  img {
    display: block;
    margin: 0 auto 15px;
  }

  @media screen and (min-width: 768px) {
    font-size: 18px;
  }

  @media screen and (min-width: 1024px) {
    font-size: 20px;
  }

  @media screen and (min-width: 1440px) {
    font-size: 22px;
  }
`;

const Header: React.FC = () => {
  const scrollToForm = () => {
    const element = document.getElementById("register-form");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const scrollToProducts = () => {
    const element = document.getElementById("products");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <HeaderContainer>
      <LeftColumn>
        <Logo
          src={logo1x}
          srcSet={`${logo1x} 1x, ${logo2x} 2x`}
          alt="MediSept"
        />
        <br />
        <StyledH1>
          Nie płać zdrowiem
          <br />
          za urodę
        </StyledH1>
        <StyledH3>
          Poznaj naszą gamę produktów do dezynfekcji dla salonów urody.
          <br />
          Zamów promocyjny pakiet i odbierz prezent.
        </StyledH3>
        <RegisterButton onClick={scrollToForm}>
          Zarejestruj pakiet
        </RegisterButton>
        <ProductsButton onClick={scrollToProducts}>
          Poznaj produkty
        </ProductsButton>
      </LeftColumn>
      <Question>Do kogo kierujemy akcję?</Question>
      <SalonsContainer>
        <SalonItem><img src={Salon1Img} alt="" />Salony kosmetyczne</SalonItem>
        <SalonItem><img src={Salon2Img} alt="" />Salony fryzjerskie i barberskie</SalonItem>
        <SalonItem><img src={Salon3Img} alt="" />Salony tatuażu</SalonItem>
        <SalonItem><img src={Salon4Img} alt="" />Salony masażu</SalonItem>
        <SalonItem><img src={Salon5Img} alt="" />Gabinety medycyny estetycznej</SalonItem>
        <SalonItem><img src={Salon6Img} alt="" />Salony manicure lub pedicure</SalonItem>
        <SalonItem><img src={Salon7Img} alt="" />Podolodzy</SalonItem>
      </SalonsContainer>
    </HeaderContainer>
  );
};

export default Header;
